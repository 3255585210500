import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { motion } from "framer-motion"

export const Background = styled(motion.div)`
	width: 100%;
	height: 100%;
	background: none;
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
`;

export const ModalWrapper = styled(motion.div)`
  height: 98vh;
  display: flex;
  position: relative;
  z-index: 100;
`;

export const CloseModalButton = styled(MdClose)`
	cursor: pointer;
	position: absolute;
    color: #fff;
	top: 20px;
	right: 20px;
	width: 32px;
	height: 32px;
	padding: 0;
	z-index: 10;
`;
