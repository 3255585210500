import styled from 'styled-components';
import {motion} from "framer-motion";




export const HeroSection = styled.section`
	height: 100vh;
    width: 100%;
	background-position: center;
	background-size: cover;
	padding-top: clamp(50px, 25vh, 220px);
	box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
`;

export const HeroVideo = styled.video`
	object-fit: cover;
  opacity:90%;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
	top: 0;
	position: absolute;
	z-index: -1;
`;


export const ButtonWrapper = styled(motion.div)`
  width: 100%;
  margin-top: -20%;
  background: black;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  @media screen and (max-width: 768px) {
    margin-top: 80%;
  }
`;


