import React from "react";
import Hero from "../Components/Hero/Hero";

export default function Home() {
    return (
        <>
            <Hero
                video = './assets/vavada.mp4'
                btn = 'VAVADA CASINO'
            />
        </>
    )
}
