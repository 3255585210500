import React, {useEffect, useState} from 'react';
import {useAnimation} from 'framer-motion';
import {useInView} from "react-intersection-observer";
import Modal from "../Modal/Modal";

import {Button, Container, NameButton,} from '../../GlobalStyles';
import {HeroVideo, HeroSection, ButtonWrapper,} from './HeroStyles';


const Hero = (props) => {

    const animation = useAnimation();
    const {ref, inView} = useInView({threshold: 0.5});
    const btn = {opacity: 0, y: 50};
    const [showModal, setShowModal] = useState(false);
    
    const toggleModal = () => {
        if (!showModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        setShowModal(!showModal);
    };
    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                x: 0,
                y: 0,
            });
        }
    }, [inView, animation]);


    return (
        <>
            <HeroSection ref={ref}>
                <HeroVideo src={props.video} autoPlay loop muted/>
                <Container>
                    <ButtonWrapper
                        initial={btn}
                        transition={{delay: 0.3, duration: 1.7}}
                        animate={animation}
                    >
                        <Button onClick={toggleModal} className={'corner'}>
                            <NameButton>{props.btn}</NameButton>
                        </Button>
                    </ButtonWrapper>
                </Container>
            </HeroSection>
            <Modal showModal={showModal} toggleModal={toggleModal}/>
        </>
    )
        ;
};

export default Hero;
