import {CasinoCard, CasinoSection} from "./CasinoStyles";
import {useEffect, useState} from "react";
import Loader from "../Loader/Loader";

function Casino(props) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        window.location.href = props.link
        setTimeout(() => setShow(true), 1000)
    })

    return (
        <>
            <CasinoSection>
                <CasinoCard>
                    {show}  <h2> Поиск зеркала </h2> <br/>
                </CasinoCard>
                <CasinoCard>
                    <Loader/>
                </CasinoCard>
            </CasinoSection>
        </>
    );
}

export default Casino;
