import React from 'react'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./Pages/Home";
import Casino from "./Components/Casino/Casino";

export default function App() {
 return (
     <>
         <BrowserRouter>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/vavada" element={<Casino link = 'https://partnervavadarv.com/?promo=0a20713e-f26a-491b-853c-b92607f104dd&target=register'/>}/>
            </Routes>
         </BrowserRouter>
     </>
 )
}
