import React from 'react';
import {
    FormColumn,
    FormWrapper,
    FormSection,
    FormRow,
    FormButton,
    FormTitle,
    FormText,
    FormSpan,
} from './FormStyles';
import {Link} from "react-router-dom";

const Form = () => {
    return (
        <FormSection>
                <FormRow>
                    <FormColumn small>
                        <FormTitle>Информация</FormTitle>
                        <FormWrapper>
                            <FormText>
                                Уважаемые игроки! <br/>
                                Welcome pack. <br/>
                                100% + 100 FS <br/>
                                Promo codes: <br/>
                                - NONE <br/>
                                - NONE
                            </FormText>

                            <Link to='vavada'>
                                <FormButton>
                                    ВОЙТИ
                                </FormButton>
                            </Link>
                            <FormSpan>
                                 vavada vavadа casino регистрация online зеркало casino vavada  рабочее зеркало  онлайн  играть  казино вавада  на деньги  бесплатно  вавада казино официальный вход
                                 бонус  войти вход слоты бездепозитный скачать официальный вавада промокод бонус за регистрацию  промоеод free официальный сайт  сайт  мобильная  игровые автоматы  bonus  888  2024 бездеп
                            </FormSpan>
                        </FormWrapper>
                    </FormColumn>
                </FormRow>
        </FormSection>
    );
};

export default Form;
