import React, {Suspense} from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import GlobalStyles, {Container} from "./GlobalStyles";
import Loader from "./Components/Loader/Loader";

const loadingMarkup = (
    <Container>
        <Loader/>
    </Container>
)

ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
        <React.StrictMode>
            <GlobalStyles/>
            <App />
        </React.StrictMode>
    </Suspense>,
    document.getElementById('root')
)
